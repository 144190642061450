const initialState = {
    loading: false,
    initialLoading: true,
    internetConnection: false,
    serverConnection: false,
    auditoriums: [],
    faculties: [],
}

export default initialState
